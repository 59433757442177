import { devices } from 'helpers/devices'
import Background from 'assets/fundoCadastro.png'
import styled from 'styled-components'
import { useState } from 'react'
import {
  InputCelPhone,
  InputCEP,
  InputCNPJ,
  InputCPF,
  InputDate,
  InputDDDPhone,
  InputEmail,
  InputLogin,
  InputName,
  InputPhone,
  InputSelect,
  InputText,
  InputUF
} from 'components/generic/GenericInputs'
import { UserModel, UserTypeEnum } from 'interfaces/user'
import InitialPerson from 'assets/initialPerson.png'
import LegalPerson from 'assets/legalPerson.png'
import PhysicalPerson from 'assets/physicalPerson.png'
import ForeignPerson from 'assets/foreignPerson.png'
import GenericButton from 'components/generic/GenericButton'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { axiosAuth, axiosEmail } from 'helpers/axiosInstances'
import { clearLoading, setGlobalMessage, showLoading } from 'redux/actions'
import { isUnauthorized } from 'helpers/errors'
import { Button, Progress } from '@chakra-ui/react'

const Container = styled.section`
  background-image: url('${Background}');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.color.white1};
  height: 100vh;
  max-height: 100%;
  width: 100vw;
  max-width: 100%;
  overflow-y: auto;

  @media ${devices.phoneOnly} {
    background-position: center left;
  }
`

const TemplateWizard = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem ${(props) => props.theme.margins.from_sides};

  @media ${devices.phoneOnly} {
    padding: 1rem ${(props) => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletPortrait} {
    padding: 1rem ${(props) => props.theme.margins.from_sides_mobile};
  }

  @media ${devices.tabletLandscape} {
    padding: 2rem ${(props) => props.theme.margins.from_sides};
  }
`

const CardForm = styled.form`
  padding: 2rem;
  box-shadow: 1px 2px 4px gray;
  background-color: ${(props) => props.theme.color.white1};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
  height: 600px;
  overflow-y: auto;
`

const LeftSide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
`

const RightSide = styled.div`
  height: 100%;
  width: 100%;
  max-width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`

const Title = styled.p`
  margin: 1rem 0;
  font-size: 2.7rem;
  background-color: ${(props) => props.theme.color.white1};
  color: ${(props) => props.theme.color.gray2};
  font-weight: bold;
  text-transform: uppercase;
`

const SpanBrown = styled.span`
  color: #aa6443;
`

// const Progress = styled.progress`
//   --_track: hsl(228 100% 90%);
//   --_progress: hsl(228 100% 50%);
//   color-scheme: #ebeced;
//   accent-color: #0f66b0;
//   width: 80%;
//   height: 25px;
// `;

const TitleItems = styled.p`
  margin: 1rem 0.5rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.color.gray2};
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
`

const TextItems = styled.p`
  margin: 1rem 0.5rem;
  font-size: 1rem;
  color: ${(props) => props.theme.color.gray2};
  font-weight: 500;
  line-height: 20px;
`

const Logo = styled.img`
  height: auto;
  width: 100%;
`

const DivButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const ButtonConfirm = styled(GenericButton)<{
  isDeny?: boolean
  isDisabled?: boolean
}>`
  margin: 1rem 0.2rem;
  min-width: 180px;
  color: ${(props) =>
    props.isDeny
      ? props.theme.color.white1
      : props.isDisabled
      ? props.theme.color.white1
      : props.theme.color.primary_main};
  background-color: ${(props) =>
    props.isDeny
      ? props.theme.color.button_deny
      : props.isDisabled
      ? props.theme.color.gray3
      : props.theme.color.secondary_main};

  &:hover {
    background-color: ${(props) =>
      props.isDeny
        ? props.theme.color.button_deny_hover
        : props.isDisabled
        ? props.theme.color.gray4
        : props.theme.color.secondary_light};
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  }
`

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  height: auto;
`

type Steps =
  | 'step0'
  | 'step1'
  | 'step2'
  | 'step3'
  | 'step4'
  | 'step5'
  | 'step2-1'

const PreRegistration = (): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [render, setRender] = useState<Steps>('step0')
  const [userData, setUserData] = useState<UserModel>({})
  const [progressValue, setProgressValue] = useState<number>(0)

  const imageRender = () => {
    if (userData.cad_type === 'JURIDICO' && render !== 'step0') {
      return LegalPerson
    } else if (userData.cad_type === 'FISICO' && render !== 'step0') {
      return PhysicalPerson
    } else if (userData.cad_type === 'ESTRANGEIRO' && render !== 'step0') {
      return ForeignPerson
    } else {
      return InitialPerson
    }
  }

  const backStep = () => {
    switch (render) {
      case 'step0':
        history.push('/')
        break
      case 'step1':
        setRender('step0')
        setProgressValue(0)
        break
      case 'step2':
        setRender('step1')
        setProgressValue(20)
        break
      case 'step3':
        setRender('step2')
        setProgressValue(40)
        break
      case 'step4':
        setRender('step3')
        setProgressValue(60)
        break
      case 'step2-1':
        setRender('step1')
        setProgressValue(20)
        break
      default:
        console.log(`Sorry, we are out of ${render}.`)
    }
  }

  const advancedStep = () => {
    switch (render) {
      case 'step0':
        setProgressValue(20)
        setRender('step1')
        break
      case 'step1':
        checkClienteSys()
        break
      case 'step2':
        setRender('step3')
        setProgressValue(60)
        break
      case 'step3':
        setRender('step4')
        setProgressValue(80)
        break
      case 'step4':
        handleCreateUser()
        break
      case 'step5':
        history.push('/login')
        break
      case 'step2-1':
        handleClienteSys()
        break
      default:
        console.log(`Sorry, we are out of ${render}.`)
    }
  }

  const checkClienteSys = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Validando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosAuth.get(
        `/user/doc?document=${userData.document}&email=${userData.email}`,
        config
      )

      setRender('step2-1')
      setProgressValue(80)
      setUserData({
        ...userData,
        fantasy_name: data.fantasia,
        user_name: data.razao_social
      })
    } catch (error: any) {
      setUserData({
        ...userData,
        fantasy_name: '',
        user_name: ''
      })
      dispatch(clearLoading())
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else if (error.response!.data.error.flag === 'newClient') {
        setRender('step2')
        setProgressValue(40)
      } else if (error.response!.data.error.flag === 'divergence') {
        dispatch(
          setGlobalMessage({
            message: `${error.response!.data.error.message}`,
            type: 'WARN'
          })
        )
        history.push('/')
        localStorage.clear()
      } else {
        dispatch(
          setGlobalMessage({
            message: `${error.response!.data.error.message}`,
            type: 'ERROR'
          })
        )
        history.push('/')
        localStorage.clear()
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const handleClienteSys = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      const response = await axiosAuth.get(
        `/check/login/${userData.login}`,
        config
      )

      const body = {
        document: userData.document,
        email: userData.email,
        login: userData.login
      }

      if (response) {
        const { data } = await axiosAuth.post(`/user/create`, body, config)
        if (data) {
          setRender('step5')
          setProgressValue(100)
          // const bodyMail = {
          //   emails: [data.email],
          //   user: data.user_name,
          //   password: data.password,
          //   login: userData.login
          // }
          // const res = await axiosEmail.post(`/first-access`, bodyMail, config)
          // if (res) {
          //   setRender('step5')
          //   setProgressValue(100)
          // } else {
          //   throw res
          // }
        } else {
          throw data
        }
      } else {
        throw response
      }
    } catch (error: any) {
      dispatch(clearLoading())
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `${error.response!.data.error.message}`,
            type: 'ERROR'
          })
        )
        history.push('/')
        localStorage.clear()
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const handleCreateUser = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      const response = await axiosAuth.get(
        `/check/login/${userData.login}`,
        config
      )

      if (response) {
        const { data } = await axiosAuth.post(`/c-users`, userData, config)
        if (data) {
          setRender('step5')
          setProgressValue(100)
        } else {
          throw data
        }
      } else {
        throw response
      }
    } catch (error: any) {
      dispatch(clearLoading())
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `${error.response!.data.error.message}`,
            type: 'ERROR'
          })
        )
        history.push('/')
        localStorage.clear()
      }
    } finally {
      dispatch(clearLoading())
    }
  }
  return (
    <Container>
      <TemplateWizard>
        <CardForm
          onSubmit={(event) => {
            event.preventDefault()
            advancedStep()
          }}
        >
          <LeftSide>
            <Title>
              Faça parte do <SpanBrown>Dascam Direct</SpanBrown>
            </Title>
            <Progress
              value={progressValue}
              colorScheme='green'
              size='lg'
            ></Progress>
            {render === 'step0' ? (
              <div>
                <TitleItems>Selecione o tipo do cliente</TitleItems>
                <InputSelect
                  value={userData.cad_type}
                  maxWidth='250px'
                  required
                  changeValue={(value) =>
                    setUserData({
                      ...userData,
                      cad_type: value as UserTypeEnum
                    })
                  }
                >
                  <option value='' selected disabled>
                    {' '}
                  </option>
                  <option value='JURIDICO'>Pessoa Jurídica</option>
                  {/*<option value="FISICO">Pessoa Física</option>
                  <option value="ESTRANGEIRO">Estrangeiro</option>*/}
                </InputSelect>
              </div>
            ) : null}
            {render === 'step1' ? (
              <div>
                <TitleItems>Preencha os campos</TitleItems>
                <ItemRow>
                  <InputEmail
                    value={userData.email}
                    label='E-mail *'
                    maxWidth='300px'
                    required
                    changeValue={(value) =>
                      setUserData({
                        ...userData,
                        email: value as UserTypeEnum
                      })
                    }
                  />
                  {userData.cad_type === 'JURIDICO' ? (
                    <InputCNPJ
                      value={userData.document}
                      label='CNPJ *'
                      maxWidth='300px'
                      required
                      changeValue={(value) =>
                        setUserData({
                          ...userData,
                          document: value
                        })
                      }
                    />
                  ) : null}
                  {userData.cad_type === 'FISICO' ? (
                    <InputCPF
                      value={userData.document}
                      label='CPF *'
                      maxWidth='300px'
                      required
                      changeValue={(value) =>
                        setUserData({
                          ...userData,
                          document: value
                        })
                      }
                    />
                  ) : null}
                  {userData.cad_type === 'ESTRANGEIRO' ? (
                    <InputText
                      value={userData.document}
                      label='RNE *'
                      maxWidth='300px'
                      required
                      changeValue={(value) =>
                        setUserData({
                          ...userData,
                          document: value
                        })
                      }
                    />
                  ) : null}
                </ItemRow>
              </div>
            ) : null}
            {render === 'step2' ? (
              <div>
                <TitleItems>Comece sua jornada na Dascam</TitleItems>
                <ItemRow>
                  {userData.cad_type === 'JURIDICO' ? (
                    <>
                      <InputName
                        value={userData.user_name}
                        label='Razão social *'
                        maxWidth='300px'
                        required
                        changeValue={(value) =>
                          setUserData({
                            ...userData,
                            user_name: value
                          })
                        }
                      />
                      <InputName
                        value={userData.fantasy_name}
                        label='Nome fantasia *'
                        maxWidth='300px'
                        required
                        changeValue={(value) =>
                          setUserData({
                            ...userData,
                            fantasy_name: value
                          })
                        }
                      />
                    </>
                  ) : null}
                  {userData.cad_type === 'FISICO' ? (
                    <>
                      <InputName
                        value={userData.user_name}
                        label='Nome *'
                        maxWidth='300px'
                        required
                        changeValue={(value) =>
                          setUserData({
                            ...userData,
                            user_name: value
                          })
                        }
                      />
                    </>
                  ) : null}
                  {userData.cad_type === 'ESTRANGEIRO' ? (
                    <>
                      <InputName
                        value={userData.user_name}
                        label='Nome *'
                        maxWidth='300px'
                        required
                        changeValue={(value) =>
                          setUserData({
                            ...userData,
                            user_name: value
                          })
                        }
                      />
                    </>
                  ) : null}
                  <InputLogin
                    value={userData.login}
                    label='Login de acesso *'
                    maxWidth='300px'
                    required
                    changeValue={(value) =>
                      setUserData({
                        ...userData,
                        login: value
                      })
                    }
                  />
                </ItemRow>
                <ItemRow>
                  {userData.cad_type === 'JURIDICO' ? (
                    <>
                      <InputDate
                        value={userData.opening_date}
                        label='Data de abertura *'
                        maxWidth='300px'
                        required
                        changeValue={(value) =>
                          setUserData({
                            ...userData,
                            opening_date: value
                          })
                        }
                      />
                      <InputCNPJ
                        value={userData.document}
                        label='CNPJ *'
                        maxWidth='300px'
                        required
                        readOnly
                        changeValue={(value) =>
                          setUserData({
                            ...userData,
                            document: value
                          })
                        }
                      />
                    </>
                  ) : null}
                  {userData.cad_type === 'FISICO' ? (
                    <>
                      <InputDate
                        value={userData.birthdate}
                        label='Data de nascimento *'
                        max={new Date().toISOString().split('T')[0]}
                        maxWidth='300px'
                        required
                        changeValue={(value) =>
                          setUserData({
                            ...userData,
                            birthdate: value
                          })
                        }
                      />
                      <InputCPF
                        value={userData.document}
                        label='CPF *'
                        maxWidth='300px'
                        required
                        readOnly
                        changeValue={(value) =>
                          setUserData({
                            ...userData,
                            document: value
                          })
                        }
                      />
                    </>
                  ) : null}
                  {userData.cad_type === 'ESTRANGEIRO' ? (
                    <>
                      <InputDate
                        value={userData.birthdate}
                        label='Data de nascimento *'
                        max={new Date().toISOString().split('T')[0]}
                        maxWidth='300px'
                        required
                        changeValue={(value) =>
                          setUserData({
                            ...userData,
                            birthdate: value
                          })
                        }
                      />
                      <InputText
                        value={userData.document}
                        label='RNE *'
                        maxWidth='300px'
                        required
                        readOnly
                        changeValue={(value) =>
                          setUserData({
                            ...userData,
                            document: value
                          })
                        }
                      />
                    </>
                  ) : null}
                </ItemRow>
              </div>
            ) : null}
            {render === 'step3' ? (
              <div>
                <TitleItems>Dados para contato</TitleItems>
                <ItemRow>
                  <InputEmail
                    value={userData.email}
                    label='E-mail *'
                    maxWidth='300px'
                    required
                    readOnly
                    changeValue={(value) =>
                      setUserData({
                        ...userData,
                        email: value
                      })
                    }
                  />
                </ItemRow>
                <ItemRow>
                  <InputDDDPhone
                    label='DDD *'
                    placeholder='00'
                    maxWidth='100px'
                    value={userData.ddd_cellphone!}
                    changeValue={(value) =>
                      setUserData({
                        ...userData,
                        ddd_cellphone: value
                      })
                    }
                    readOnly={userData.status === 'EVALUATION'}
                  />
                  <InputCelPhone
                    value={userData.contact_cellphone}
                    label='Celular *'
                    maxWidth='300px'
                    required
                    changeValue={(value) =>
                      setUserData({
                        ...userData,
                        contact_cellphone: value
                      })
                    }
                  />
                  <InputDDDPhone
                    label='DDD *'
                    placeholder='00'
                    maxWidth='100px'
                    value={userData.ddd_phone!}
                    changeValue={(value) =>
                      setUserData({
                        ...userData,
                        ddd_phone: value
                      })
                    }
                    readOnly={userData.status === 'EVALUATION'}
                  />
                  <InputPhone
                    value={userData.contact_phone}
                    label='Telefone'
                    maxWidth='300px'
                    changeValue={(value) =>
                      setUserData({
                        ...userData,
                        contact_phone: value
                      })
                    }
                  />
                </ItemRow>
              </div>
            ) : null}{' '}
            {render === 'step4' ? (
              <div>
                <TitleItems>Preencha os campos com seu endereço</TitleItems>
                <ItemRow>
                  {/* <InputCountry
                    value={userData.country}
                    label="País *"
                    maxWidth="180px"
                    required
                    changeValue={value =>
                      setUserData({
                        ...userData,
                        country: value,
                      })
                    }
                  /> */}
                  <InputCEP
                    label='CEP'
                    maxWidth='175px'
                    placeholder='_____-___'
                    value={userData.address_zipcode}
                    changeValue={(value) =>
                      setUserData({
                        ...userData,
                        address_zipcode: value
                      })
                    }
                    changeOtherValue={(value) =>
                      setUserData({
                        ...userData,
                        address_city: value.localidade,
                        address_state: value.uf,
                        address_place: value.logradouro,
                        address_district: value.bairro
                      })
                    }
                    required
                  />
                  <InputText
                    label='Cidade *'
                    maxWidth='200px'
                    value={userData.address_city!}
                    changeValue={(value) =>
                      setUserData({
                        ...userData,
                        address_city: value
                      })
                    }
                    required
                  />
                  <InputUF
                    label='UF *'
                    value={userData.address_state!}
                    changeValue={(value) =>
                      setUserData({
                        ...userData,
                        address_state: value
                      })
                    }
                    required
                  />
                </ItemRow>
                <ItemRow>
                  <InputText
                    label='Bairro *'
                    maxWidth='250px'
                    value={userData.address_district!}
                    changeValue={(value) =>
                      setUserData({
                        ...userData,
                        address_district: value
                      })
                    }
                    required
                  />
                  <InputText
                    label='Logradouro *'
                    maxWidth='300px'
                    value={userData.address_place!}
                    changeValue={(value) =>
                      setUserData({
                        ...userData,
                        address_place: value
                      })
                    }
                    required
                  />
                  <InputText
                    label='Número *'
                    maxWidth='100px'
                    value={userData.address_number!}
                    changeValue={(value) =>
                      setUserData({
                        ...userData,
                        address_number: value
                      })
                    }
                    required
                  />
                  <InputText
                    label='Complemento'
                    maxWidth='340px'
                    value={userData.address_complement!}
                    changeValue={(value) =>
                      setUserData({
                        ...userData,
                        address_complement: value
                      })
                    }
                  />
                </ItemRow>
              </div>
            ) : null}
            {render === 'step5' ? (
              <div>
                <TitleItems>Cadastro realizado com sucesso</TitleItems>
                <TextItems>
                  Em breve você receberá um e-mail com sua senha no e-mail
                  informado, caso não receba olhe sua caixa de SPAM ou entre em
                  contato com a Equipe da Dascam
                </TextItems>
              </div>
            ) : null}
            {render === 'step2-1' ? (
              <div>
                <TitleItems>Você já é cliente Dascam</TitleItems>
                <ItemRow>
                  {userData.cad_type === 'JURIDICO' ? (
                    <>
                      <InputName
                        value={userData.user_name}
                        label='Razão social *'
                        maxWidth='300px'
                        required
                        changeValue={(value) =>
                          setUserData({
                            ...userData,
                            user_name: value
                          })
                        }
                        readOnly
                      />
                      <InputName
                        value={userData.fantasy_name}
                        label='Nome fantasia *'
                        maxWidth='300px'
                        required
                        changeValue={(value) =>
                          setUserData({
                            ...userData,
                            fantasy_name: value
                          })
                        }
                        readOnly
                      />
                    </>
                  ) : null}
                  {userData.cad_type === 'FISICO' ? (
                    <>
                      <InputName
                        value={userData.user_name}
                        label='Nome *'
                        maxWidth='300px'
                        required
                        changeValue={(value) =>
                          setUserData({
                            ...userData,
                            user_name: value
                          })
                        }
                        readOnly
                      />
                    </>
                  ) : null}
                  {userData.cad_type === 'ESTRANGEIRO' ? (
                    <>
                      <InputName
                        value={userData.user_name}
                        label='Nome *'
                        maxWidth='300px'
                        required
                        changeValue={(value) =>
                          setUserData({
                            ...userData,
                            user_name: value
                          })
                        }
                        readOnly
                      />
                    </>
                  ) : null}
                  {userData.cad_type === 'JURIDICO' ? (
                    <>
                      <InputCNPJ
                        value={userData.document}
                        label='CNPJ *'
                        maxWidth='300px'
                        required
                        changeValue={(value) =>
                          setUserData({
                            ...userData,
                            document: value
                          })
                        }
                        readOnly
                      />
                    </>
                  ) : null}
                  {userData.cad_type === 'FISICO' ? (
                    <>
                      <InputCPF
                        value={userData.document}
                        label='CPF *'
                        maxWidth='300px'
                        required
                        changeValue={(value) =>
                          setUserData({
                            ...userData,
                            document: value
                          })
                        }
                        readOnly
                      />
                    </>
                  ) : null}
                  {userData.cad_type === 'ESTRANGEIRO' ? (
                    <>
                      <InputText
                        value={userData.document}
                        label='RNE *'
                        maxWidth='300px'
                        required
                        changeValue={(value) =>
                          setUserData({
                            ...userData,
                            document: value
                          })
                        }
                        readOnly
                      />
                    </>
                  ) : null}
                </ItemRow>
                <ItemRow>
                  <InputEmail
                    value={userData.email}
                    label='E-mail *'
                    maxWidth='300px'
                    required
                    changeValue={(value) =>
                      setUserData({
                        ...userData,
                        email: value as UserTypeEnum
                      })
                    }
                    readOnly
                  />
                  <InputLogin
                    value={userData.login}
                    label='Login de acesso *'
                    maxWidth='300px'
                    required
                    changeValue={(value) =>
                      setUserData({
                        ...userData,
                        login: value
                      })
                    }
                  />
                </ItemRow>
              </div>
            ) : null}
            <DivButton>
              {render !== 'step5' ? (
                <Button
                  variant={'outline'}
                  color={'red'}
                  colorScheme={'red'}
                  w={'25%'}
                  size={'md'}
                  mt={6}
                  mr={6}
                  onClick={() => backStep()}
                >
                  {render !== 'step0' ? 'Voltar' : 'Cancelar'}
                </Button>
              ) : null}

              <Button
                variant={'ghost'}
                color={'primary.400'}
                colorScheme={'primary'}
                w={'25%'}
                size={'md'}
                mt={6}
                type='submit'
              >
                {render === 'step5' || render === 'step4'
                  ? 'Confirmar'
                  : 'Avançar'}
              </Button>
            </DivButton>
          </LeftSide>
          <RightSide>
            <Logo src={imageRender()} alt='' />
          </RightSide>
        </CardForm>
      </TemplateWizard>
    </Container>
  )
}

export default PreRegistration
