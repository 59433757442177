import { Box } from '@chakra-ui/react'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'

const BasicData: React.FC = ({ children }) => {
  return (
    <Box m={4}>
      <SimpleFormGenerator
        rows={[
          {
            columns: 3,
            fields: [
              {
                type: 'date',
                label: 'Data do Cadastro',
                name: 'initial_date',
                readOnly: true
              },
              {
                type: 'text',
                label: 'CNPJ',
                name: 'operation_number',
                readOnly: true
              }
            ]
          },
          {
            columns: 3,
            fields: [
              {
                type: 'select',
                label: 'Tipo do Cadastro',
                name: 'contracts_type',
                options: [
                  {
                    label: () => 'ABERTURA',
                    value: 1
                  },
                  {
                    label: () => 'RENOVAÇÃO',
                    value: 2
                  }
                ]
              },
              {
                type: 'select',
                label: 'Tipo de Relacionamento',
                name: 'contracts_type',
                options: [
                  {
                    label: () => 'CAMBIO',
                    value: 1
                  },
                  {
                    label: () => 'ASSESSORIA',
                    value: 2
                  },
                  {
                    label: () => 'AMBOS',
                    value: 3
                  }
                ]
              },
              {
                type: 'text',
                label: 'Gerente DASCAM',
                name: 'operation_number'
              }
            ]
          },
          {
            columns: 1,
            fields: [
              {
                type: 'textarea',
                label: 'Proposito do Relacionamento',
                name: 'operation_number'
              }
            ]
          }
        ]}
        value={{}}
        onChange={(v) => {}}
      />
    </Box>
  )
}

export default BasicData
