import GenericButton from 'components/generic/GenericButton'
import usePagination from 'hooks/usePagination'
import styled from 'styled-components'
import { useCallback, useEffect, useState } from 'react'
import { UserModel } from 'interfaces/user'
import { axiosAuth } from 'helpers/axiosInstances'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'redux/types'
import { isUnauthorized } from 'helpers/errors'
import { useHistory } from 'react-router-dom'
import { setGlobalMessage, clearLoading, showLoading } from 'redux/actions'
import RegisterUser from 'components/restricted-area/registration-data/data/user/subComponents/RegisterUser'
import { BiShow } from 'react-icons/bi'
import { Button, IconButton } from '@chakra-ui/react'
import { Col } from 'v2/components/shared/Col'
import TableRender from 'v2/components/shared/TableRender'
import EditUser from 'components/restricted-area/registration-data/data/user/subComponents/EditUser'
import VisualUser from 'components/restricted-area/registration-data/data/user/subComponents/VisualUser'
import { HStack } from '@chakra-ui/react'
import { AiOutlineEdit } from 'react-icons/ai'
import ActiveUser from 'components/restricted-area/registration-data/data/user/subComponents/ActiveUser'
import DisableUser from 'components/restricted-area/registration-data/data/user/subComponents/DisableUser'
import { ReactComponent as ActiveIcon } from 'assets/activeIcon.svg'
import { ReactComponent as InactiveIcon } from 'assets/inactiveIcon.svg'

interface UsersClientProps {
  company_id: string
}

const UsersClient: React.FC<UsersClientProps> = ({ company_id }) => {
  const pageLimit = 200
  const user = useSelector((state: GlobalState) => state.user)
  const dispatch = useDispatch()
  const history = useHistory()
  const [currentPage] = usePagination(pageLimit)
  const [registerUser, setRegisterUser] = useState<boolean>(false)
  const [editUser, setEditUser] = useState<boolean>(false)
  const [showUser, setShowUser] = useState<boolean>(false)
  const [activeUser, setActiveUser] = useState<boolean>(false)
  const [disableUser, setDisableUser] = useState<boolean>(false)
  const [idUserToAction, setIdUserToAction] = useState<string>()
  const [users, setUsers] = useState<UserModel[]>([])

  const getUserData = useCallback(async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosAuth.get(
        `/filter-users?limit=${pageLimit}&offset=${
          (currentPage - 1) * pageLimit
        }&cad_type=AUTORIZADO&company_id=${company_id}`,
        config
      )

      setUsers(data.users)
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados de banco do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, dispatch, history, idUserToAction])

  useEffect(() => {
    getUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Col px={4} minH={500}>
        <Col maxW={60}>
          <Button
            variant='outline'
            color={'primary.400'}
            colorScheme={'primary'}
            onClick={() => setRegisterUser(!registerUser)}
          >
            Adicionar usuário
          </Button>
        </Col>

        <Col
          mt={2}
          rounded={'lg'}
          overflow={'hidden'}
          border={'1px solid transparent'}
          borderColor={'primary.600'}
        >
          <TableRender
            _thead={{
              _tr: { bg: 'primary.600' },
              _th: {
                color: 'white',
                border: 0,
                fontSize: '10px',
                px: 1,
                py: 2
              }
            }}
            config={{
              columns: [
                {
                  key: 'user_name',
                  title: 'Nome',
                  defaultLabel: 'Não definido'
                },
                {
                  key: 'contact_role',
                  title: 'Empresa',
                  defaultLabel: 'Não definido'
                },
                {
                  key: 'contact_branch',
                  title: 'Departamento',
                  defaultLabel: 'Não definido'
                },
                {
                  key: 'email',
                  title: 'E-mail',
                  defaultLabel: 'Não definido'
                },
                {
                  key: 'contact_cellphone',
                  title: 'Celular',
                  defaultLabel: 'Não definido'
                },
                {
                  key: 'contact_phone',
                  title: 'Telefone',
                  defaultLabel: 'Não definido'
                },
                {
                  key: 'action',
                  title: 'Ações',
                  defaultLabel: 'Ações',
                  render: (value: any, item: any) => {
                    return (
                      <HStack>
                        <IconButton
                          aria-label='Visualizar'
                          size={'sm'}
                          colorScheme={'blue'}
                          onClick={() => {
                            setIdUserToAction(item.id)
                            setShowUser(true)
                          }}
                        >
                          <BiShow size={16} />
                        </IconButton>
                        <IconButton
                          aria-label='Editar'
                          size={'sm'}
                          colorScheme={'blue'}
                          onClick={() => {
                            setIdUserToAction(item.id)
                            setEditUser(true)
                          }}
                        >
                          <AiOutlineEdit size={16} />
                        </IconButton>
                        <Col width={'0'}>
                          <ButtonStatus
                            isActive={item.status === 'ACTIVE' ? true : false}
                            type='button'
                            onClick={() => {
                              setIdUserToAction(item.id)
                              item.status === 'ACTIVE'
                                ? setDisableUser(!disableUser)
                                : setActiveUser(!activeUser)
                            }}
                          >
                            {item.status === 'ACTIVE' ? (
                              <ActiveIcon />
                            ) : (
                              <InactiveIcon />
                            )}
                          </ButtonStatus>
                        </Col>
                      </HStack>
                    )
                  }
                }
              ]
            }}
            data={users}
          />
        </Col>
      </Col>
      <RegisterUser
        showModal={registerUser}
        handleModal={() => setRegisterUser(!registerUser)}
        getUserData={() => getUserData()}
        company_id={Number(company_id)}
      />
      <EditUser
        id={String(idUserToAction)}
        showModal={editUser}
        handleModal={() => setEditUser(!editUser)}
        getUserData={() => getUserData()}
      />
      <VisualUser
        id={String(idUserToAction)}
        showModal={showUser}
        handleModal={() => setShowUser(!showUser)}
      />
      <ActiveUser
        id={String(idUserToAction)}
        showModal={activeUser}
        handleModal={() => setActiveUser(!activeUser)}
        getUserData={() => getUserData()}
      />
      <DisableUser
        id={String(idUserToAction)}
        showModal={disableUser}
        handleModal={() => setDisableUser(!disableUser)}
        getUserData={() => getUserData()}
      />
    </>
  )
}

const ButtonStatus = styled(GenericButton)<{ isActive?: boolean }>`
  width: 60px;
  height: 25px;
  padding: 0.1rem;
  border: 1px solid ${(props) => props.theme.color.white1};
  border-radius: 10px;
  background: ${(props) => props.theme.color.primary_dark};
  align-items: center;
  justify-content: ${(props) => (props.isActive ? 'flex-start' : 'flex-end')};

  &:hover {
    background: ${(props) => props.theme.color.primary_main};
  }

  & svg {
    width: 30px;
    height: auto;
  }
`

export default UsersClient
