import { Box } from '@chakra-ui/react'
import SimpleFormGenerator from 'v2/components/shared/SimpleFormGenerator'

const DataClient: React.FC = ({ children }) => {
  return (
    <Box m={4}>
      <SimpleFormGenerator
        rows={[
          {
            columns: 1,
            fields: [
              {
                type: 'heading',
                as: 'h6',
                label: `Empresa`,
                name: 'Empresa',
                size: 'xs'
              }
            ]
          },
          {
            columns: 2,
            fields: [
              {
                type: 'text',
                label: 'Razão Social',
                name: 'initial_date',
                readOnly: true
              }
            ]
          },
          {
            columns: 1,
            fields: [
              {
                type: 'heading',
                as: 'h6',
                label: `Endereço`,
                name: 'Endereço',
                size: 'xs'
              }
            ]
          },
          {
            columns: 2,
            fields: [
              {
                type: 'text',
                label: 'CEP',
                name: 'cep',
                readOnly: true
              },
              {
                type: 'text',
                label: 'Endereço Comercial',
                name: 'initial_date',
                readOnly: true
              }
            ]
          },
          {
            columns: 1,
            fields: [
              {
                type: 'heading',
                as: 'h6',
                label: `Contato de Referência`,
                name: 'Endereço',
                size: 'xs'
              }
            ]
          },
          {
            columns: 5,
            fields: [
              {
                type: 'text',
                label: 'DDD',
                name: 'operation_number'
              },
              {
                type: 'text',
                label: 'Telefone',
                name: 'operation_number'
              },
              {
                type: 'text',
                label: 'Nome de Contato',
                name: 'operation_number'
              },
              {
                type: 'text',
                label: 'E-mail de Contato',
                name: 'operation_number'
              },
              {
                type: 'text',
                label: 'Cargo',
                name: 'operation_number'
              }
            ]
          },
          {
            columns: 1,
            fields: [
              {
                type: 'heading',
                as: 'h6',
                label: `Financeiro da Empresa`,
                name: 'Endereço',
                size: 'xs'
              }
            ]
          },
          {
            columns: 4,
            fields: [
              {
                type: 'date',
                label: 'Data da Constituição',
                name: 'operation_number'
              },
              {
                type: 'select',
                label: 'Forma da Constituição',
                name: 'operation_number'
              },
              {
                type: 'select',
                label: 'Origem do Capital',
                name: 'operation_number'
              },
              {
                type: 'currency',
                label: 'Captial Social',
                name: 'operation_number'
              }
            ]
          },
          {
            columns: 3,
            fields: [
              {
                type: 'text',
                label: 'Atividade Principal',
                name: 'operation_number'
              },
              {
                type: 'currency',
                label: 'Faturamento Anual',
                name: 'operation_number'
              },
              {
                type: 'currency',
                label: 'Patrimonio Líquido',
                name: 'operation_number'
              }
            ]
          }
        ]}
        value={{}}
        onChange={(v) => {}}
      />
    </Box>
  )
}

export default DataClient
