import { Box, Button, VStack, Text, ButtonGroup, Badge } from '@chakra-ui/react'
import { useGlobals } from 'hooks/helpers/useGlobals'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CardPage from 'v2/components/pages/CardPage'
import { Col } from 'v2/components/shared/Col'
import { useHashData } from 'v2/hooks/helpers/useHashData'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { axiosWebsite } from 'helpers/axiosInstances'
import { isUnauthorized } from 'helpers/errors'
import { OperationModel } from 'interfaces/operation'
import { showLoading, setGlobalMessage, clearLoading } from 'redux/actions'
import DataTable, { TableColumn } from 'react-data-table-component'
import {
  capitalizeFirstLetter,
  formatColumn,
  formatDateHour,
  formatDateHourV2,
  formatProduct
} from 'helpers/formats'
import { apiClient } from 'v2/services/clients/apiClient'

const MonitorPage: React.FC = () => {
  const { user } = useGlobals()
  const [operationData, setOperationData] = useState<OperationModel[]>([])
  const [groupList, setGroupList] = useState<any[]>([])
  const [hidden, setDevHidden] = useState<boolean>(true)
  const dispatch = useDispatch()
  const history = useHistory()
  const { hashData, redirect } = useHashData()

  const current = new Date()
  const day =
    current.getFullYear().toString() +
    '-' +
    (current.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    current.getDate().toString().padStart(2, '0')
  const dayLabel =
    current.getDate().toString().padStart(2, '0') +
    '/' +
    (current.getMonth() + 1).toString().padStart(2, '0') +
    '/' +
    current.getFullYear().toString()

  const columns: TableColumn<any>[] = [
    {
      name: 'TIPO CONTRATO',
      selector: (row) => capitalizeFirstLetter(row.data_type) ?? '',
      sortable: true,
      width: '145px'
    },
    {
      name: 'ENV. PARA BANCO',
      selector: (row) => formatDateHourV2(row.bacen_in ?? ''),
      width: '165px',
      cell: (row) => (
        <Badge variant='solid' colorScheme={row.bacen_in ? 'green' : 'red'}>
          {row.bacen_in ? formatDateHourV2(row.bacen_in) : '---'}
        </Badge>
      )
    },
    {
      name: 'CONTRATO*',
      selector: (row) =>
        row.bacen_number === '' ? 'Aguardando' : row.bacen_number,
      sortable: true,
      cell (row, rowIndex, column, id) {
        return (
          <Badge
            variant='subtle'
            colorScheme={row.bacen_number ? 'green' : 'red'}
          >
            {row.bacen_number === '' ? 'Aguardando' : row.bacen_number}
          </Badge>
        )
      },
      conditionalCellStyles: [
        {
          when: (row) => row.bacen_number !== '',
          style: {
            color: 'green',
            fontWeight: 'bolder'
          }
        },
        {
          when: (row) => row.bacen_number === '',
          style: {
            color: 'firebrick',
            fontWeight: 'lighter',
            fontStyle: 'italic'
          }
        }
      ],
      width: '135px'
    },
    {
      name: 'PRODUTO',
      selector: (row) => formatProduct(row.product_type ?? ''),
      width: '120px'
    },
    {
      name: 'EMPRESA',
      selector: (row) =>
        groupList.find(
          (e) => Number(e.id_syscambio) === Number(row.user_syscambio)
        )?.fantasy_name ?? '',
      wrap: false,
      minWidth: '260px',
      reorder: true
    },
    {
      name: 'INSTITUIÇÃO',
      selector: (row) => row.instituicao ?? '',
      wrap: false,
      minWidth: '260px',
      reorder: true
    },
    {
      name: 'MOEDA',
      selector: (row) => row.currency_iso ?? '',
      width: '80px',
      right: true
    },
    {
      name: 'VALOR ME',
      selector: (row) => Number(row.operation_value ?? 0),
      format: (row) =>
        Number(row.operation_value ?? 0).toLocaleString('pt-br', {
          minimumFractionDigits: 2
        }) ?? '',
      sortable: true,
      width: '130px',
      right: true
    },
    {
      name: 'TAXA',
      selector: (row) =>
        Number(row.taxa ?? 0).toLocaleString('pt-br', {
          minimumFractionDigits: 4
        }),
      sortable: true,
      width: '80px',
      right: true
    },
    {
      name: 'VALOR R$',
      selector: (row) => Number(row.reais_value ?? 0),
      format: (row) =>
        Number(row.reais_value ?? 0).toLocaleString('pt-br', {
          minimumFractionDigits: 2
        }) ?? '',
      sortable: true,
      width: '130px',
      right: true
    }
  ]

  const load = useCallback(async () => {
    try {
      if (hidden === true) {
        setDevHidden(false)
      }
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true,
          subMessage: ''
        })
      )

      let itemsToFind: any[] =
        groupList.length !== 0
          ? groupList
          : await apiClient.customers.getCustomersByUser(
              user.company_id ?? user.id!
            )

      setGroupList(itemsToFind)

      const body = {
        documents: itemsToFind.map((e) => e.document),
        start_date: day,
        end_date: day,
        //start_date: '2023-12-18',
        //end_date: '2023-12-18',
        data_type: 1
      }
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
      const { data } = await axiosWebsite.post(`/monitor`, body, config)
      console.log(data)
      setOperationData(!data.error ? data : [])
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao buscar os dados: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hashData])

  useEffect(() => {
    load()
  }, [hashData])

  async function exportOperations (params: any[]) {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(
      params.map(function (e) {
        return {
          'Tipo Contrato': e.data_type,
          'Contrato (N° Banco Central)': e.bacen_number ?? '---',
          Produto: e.product_type,
          Empresa:
            groupList.find((a) => a.id_syscambio === e.user_syscambio)
              ?.fantasy_name ?? '',
          Instituição: e.instituicao,
          Moeda: e.currency_iso,
          'Valor ME': Number(e.operation_value),
          Taxa: e.taxa,
          'Valor R$': Number(e.reais_value),
          'Env. Banco': formatDateHourV2(e.bacen_in ?? '')
        }
      })
    )
    ws['!cols'] = [
      { wch: 15 },
      { wch: 28 },
      { wch: 15 },
      { wch: 30 },
      { wch: 30 },
      { wch: 8 },
      { wch: 15 },
      { wch: 8 },
      { wch: 15 },
      { wch: 30 }
    ]
    const listValueMoney = [6, 8]

    listValueMoney.forEach((element) => {
      formatColumn(ws, element, '#,##0.00')
    })

    formatColumn(ws, 7, '#,##0.0000')

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, 'Exportação Monitor' + fileExtension)
  }

  return (
    <CardPage
      title={'Monitor de Operações'}
      _headerRight={
        <Col px={2} mt={2}>
          {
            <>
              <VStack spacing={4} align='end'>
                <Box h='40px' mt={2} mb={5}>
                  <ButtonGroup gap='4'>
                    <Button
                      variant={'outline'}
                      color={'primary.400'}
                      colorScheme={'primary'}
                      onClick={() => {
                        load()
                      }}
                    >
                      Atualizar Dados
                    </Button>
                    <Button
                      isDisabled={operationData.length > 0 ? false : true}
                      variant={'outline'}
                      color={'primary.400'}
                      colorScheme={'primary'}
                      onClick={() => {
                        // exportExcel();
                        exportOperations(operationData)
                      }}
                    >
                      Gerar Relatório de Operações
                    </Button>
                  </ButtonGroup>
                </Box>
              </VStack>
            </>
          }
        </Col>
      }
    >
      <Box hidden={hidden}>
        <Box ml={2}>
          <Text fontSize={'xs'} as='em'>
            Operações fechadas no dia {dayLabel}
          </Text>
          <Text fontSize={'xs'}>CONTRATO = (N° BANCO CENTRAL)</Text>
        </Box>
        <Col
          m={2}
          rounded={'lg'}
          overflow={'hidden'}
          border={'1px solid transparent'}
          borderColor={'primary.600'}
        >
          <DataTable
            noDataComponent=''
            columns={columns}
            data={operationData}
            dense={true}
            striped={true}
          />
        </Col>
      </Box>
    </CardPage>
  )
}

export default MonitorPage
