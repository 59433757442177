import {
  Box,
  Button,
  Flex,
  Spacer,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  Text,
  useSteps
} from '@chakra-ui/react'
import { useGlobals } from 'hooks/helpers/useGlobals'
import { FaExclamationTriangle, FaMapPin } from 'react-icons/fa'
import CardPage from 'v2/components/pages/CardPage'
import BasicData from './1Basic'
import DataClient from './2DataClient'

const steps = [
  { title: 'Dados Básicos' },
  { title: 'Dados do Cliente' },
  { title: 'Composição Acionária' },
  { title: 'Representante Legal' },
  { title: 'Referêcias' },
  { title: 'Contatos' },
  { title: 'Documentos' },
  { title: 'Impressão' }
]

const RegisterBase: React.FC = ({ children }) => {
  const { user, isDascam } = useGlobals()
  const title = isDascam ? 'Gestão de Cadastro de Cliente' : 'Minha Empresa'

  const test = {
    user: 'a',
    ser: [],
    serc: ['a']
  }

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length
  })

  function completeStep (index: number) {
    switch (index) {
      case 1:
        return true
      case 2:
        return true
      case 3:
        return false
      case 4:
        return true
      case 5:
        return false
      case 6:
        return true
      case 7:
        return true
      case 8:
        return false
      default:
        break
    }
  }

  function next () {
    if (activeStep < 8) {
      setActiveStep(activeStep + 1)
    }
  }

  function back () {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1)
    }
  }

  return (
    <CardPage title={`${title}`}>
      <Box m={4}>
        <Flex>
          <Stepper colorScheme='primary' w={'full'} index={activeStep}>
            {steps.map((step, index) => (
              <Box maxH={'80px'} minH={'80px'} w={'full'}>
                <Box>
                  <Step key={index} onClick={() => setActiveStep(index)}>
                    <StepIndicator
                      sx={{
                        '[data-status=complete] &': {
                          background: 'white',
                          borderColor: '#0F4C86',
                          color: '#0F4C86',
                          border: '2px'
                        }
                      }}
                    >
                      <StepStatus
                        complete={
                          completeStep(index) ? (
                            <StepIcon color='green' />
                          ) : (
                            <FaExclamationTriangle color='red' />
                          )
                        }
                        incomplete={<StepNumber />}
                        active={<FaMapPin color='#0F4C86' />}
                      />
                    </StepIndicator>

                    <StepSeparator />
                  </Step>
                </Box>
                <Box alignContent={'center'}>
                  <Text fontWeight={'light'} fontSize={'sm'}>
                    {`${step.title}`}
                  </Text>
                </Box>
              </Box>
            ))}
          </Stepper>
        </Flex>
        <Flex gap={2}>
          <Spacer />
          <Button onClick={back} variant='outline' colorScheme={'red'}>
            Voltar
          </Button>
          <Button onClick={next} variant='outline' colorScheme={'green'}>
            Avançar
          </Button>
        </Flex>
      </Box>
      <Box>
        {activeStep === 0 ? <BasicData /> : null}
        {activeStep === 1 ? <DataClient /> : null}
        {activeStep === 2 ? <>a</> : null}
        {activeStep === 3 ? <>a</> : null}
        {activeStep === 4 ? <>a</> : null}
        {activeStep === 5 ? <>a</> : null}
        {activeStep === 6 ? <>a</> : null}
        {activeStep === 7 ? <>a</> : null}
      </Box>
    </CardPage>
  )
}

export default RegisterBase
