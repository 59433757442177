import { Avatar, Button } from '@chakra-ui/react'
import GenericButton from 'components/generic/GenericButton'
import {
  InputCelPhone,
  InputCPF,
  InputEmail,
  InputImgBase64,
  InputLogin,
  InputPhone,
  InputSelect,
  InputText
} from 'components/generic/GenericInputs'
import Modal from 'components/generic/Modal'
import PermissionList from 'components/generic/PermissionList'
import PermissionPicker from 'components/generic/PermissionPicker'
import { axiosAuth, axiosEmail } from 'helpers/axiosInstances'
import { isConflicting, isUnauthorized } from 'helpers/errors'
import { makeDascamUsersPermissionsGroups } from 'helpers/factories/permissions'
import { UserModel, UserStatusEnum, UserTypeEnum } from 'interfaces/user'
import { Permissions } from 'interfaces/web/permission'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  setGlobalMessage,
  setSuccessMessage,
  clearLoading,
  showLoading
} from 'redux/actions'
import styled from 'styled-components'

const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ModalStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
`

const LeftPane = styled.div`
  width: auto;
  min-width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  & div {
    align-items: center;
  }
`

const RightPane = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
`

const ButtonDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
`

// const DivAddClient = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   height: auto;
//   width: auto;

//   & p {
//     margin: 0.3rem;
//     font-family: Montserrat;
//     font-weight: 500;
//     font-size: 1rem;
//     line-height: 20px;
//   }
// `;

interface Props {
  showModal: boolean
  getUserData: () => void
  handleModal: () => void
}

const RegisterUser = ({
  showModal,
  getUserData,
  handleModal
}: Props): JSX.Element => {
  // const user = useSelector((state: GlobalState) => state.user);
  const dispatch = useDispatch()
  const history = useHistory()
  const [userData, setUserData] = useState<UserModel>({
    cad_type: 'DASCAM' as UserTypeEnum,
    permission_list: [],
    status: 'ACTIVE' as UserStatusEnum
  })

  const handleSubmit = async () => {
    try {
      dispatch(
        showLoading({
          message: 'Carregando',
          isLoading: true
        })
      )

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }

      const { data } = await axiosAuth.post(`/users`, userData, config)

      const body_mail = {
        emails: [userData.email],
        user: userData.user_name,
        login: userData.login,
        password: data.password
      }

      dispatch(setSuccessMessage('Usuário registrado com sucesso!'))

      await axiosEmail.post(`/first-access`, body_mail)

      close()
      getUserData()
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push('/unauthorized')
      } else if (isConflicting(error)) {
        dispatch(
          setGlobalMessage({
            message: `Conflito de dados, E-mail já cadastrado : ${error.status}`,
            type: 'ERROR'
          })
        )
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: 'ERROR'
          })
        )
      }
    } finally {
      dispatch(clearLoading())
    }
  }

  const close = () => {
    setUserData({
      cad_type: 'DASCAM' as UserTypeEnum,
      permission_list: [],
      status: 'ACTIVE' as UserStatusEnum
    })
    handleModal()
  }

  return (
    <Modal title='Incluir novo usuário' showModal={showModal} backFocus>
      <Form
        onSubmit={(event) => {
          event.preventDefault()
          handleSubmit()
        }}
      >
        <ModalStyled>
          <LeftPane>
            <Avatar
              bg={'primary.200'}
              name={userData?.user_name}
              src={userData?.photo}
              size='2xl'
            />
            {/* <Button
              m={2}
              variant='outline'
              color={"primary.400"}
              colorScheme={"primary"}
              onClick={() =>
                document.getElementById("selectedFileImg")!.click()
              }
            >
              Adicionar Foto
            </Button> */}
          </LeftPane>
          <RightPane>
            <ItemRow>
              <InputText
                maxWidth='400px'
                label='Nome *'
                required
                value={userData.user_name!}
                changeValue={(value) =>
                  setUserData({ ...userData, user_name: value })
                }
              />
            </ItemRow>
            <ItemRow>
              <InputCPF
                maxWidth='200px'
                label='CPF *'
                placeholder='___.___.___-__'
                required
                value={userData.document!}
                changeValue={(value) =>
                  setUserData({ ...userData, document: value })
                }
              />
              <InputEmail
                maxWidth='200px'
                label='E-mail *'
                placeholder='exemplo@mail.com'
                required
                value={userData.email!}
                changeValue={(value) =>
                  setUserData({ ...userData, email: value })
                }
              />
            </ItemRow>
            {/* <ItemRow>
              <InputCelPhone
                maxWidth="200px"
                label="Celular *"
                placeholder="00000-0000"
                required
                value={userData.contact_cellphone!}
                changeValue={value =>
                  setUserData({ ...userData, contact_cellphone: value })
                }
              />
              <InputPhone
                maxWidth="200px"
                label="Telefone"
                placeholder="0000-0000"
                value={userData.contact_phone!}
                changeValue={value =>
                  setUserData({ ...userData, contact_phone: value })
                }
              />
            </ItemRow> */}
            <ItemRow>
              <InputSelect
                maxWidth='200px'
                label='Departamento *'
                required
                value={userData.dascam_type!}
                changeValue={(value) =>
                  setUserData({ ...userData, dascam_type: value })
                }
              >
                <option selected disabled value=''>
                  Selecione o departamento
                </option>
                <option value='NAO INFORMADO'>NAO INFORMADO</option>
                <option value='ADMINISTRACAO'>ADMINISTRACAO</option>
                <option value='BACKOFFICE'>BACKOFFICE</option>
                <option value='CADASTRO'>CADASTRO</option>
                <option value='COMPLIANCE'>COMPLIANCE</option>
                <option value='DIRETORIA'>DIRETORIA</option>
                <option value='FINANCEIRO'>FINANCEIRO</option>
                <option value='OPERAÇÕES'>OPERAÇÕES</option>
              </InputSelect>
              <InputLogin
                value={userData.login}
                label='Login de acesso *'
                maxWidth='200px'
                required
                changeValue={(value) =>
                  setUserData({
                    ...userData,
                    login: value
                  })
                }
              />
            </ItemRow>
            {/* <ItemRow>
            <DivAddClient>
              <p>Adicionar Clientes Dascam:</p>
              <Button type="button">Selecionar clientes</Button>
            </DivAddClient>
          </ItemRow> */}
            {/* <PermissionList
              userData={userData}
              changeUserData={value => setUserData(value)}
            /> */}
            <PermissionPicker
              onChange={(permissions) => {
                setUserData((oldUser) => {
                  return {
                    ...oldUser,
                    permission_list: permissions
                  }
                })
              }}
              groups={makeDascamUsersPermissionsGroups()}
            />
          </RightPane>
        </ModalStyled>
        <ButtonDiv>
          <Button
            m={2}
            variant='outline'
            color={'red.400'}
            colorScheme={'red'}
            onClick={() => close()}
          >
            Cancelar
          </Button>
          <Button
            m={2}
            variant='ghost'
            color={'green.400'}
            colorScheme={'green'}
            onClick={() => handleSubmit()}
          >
            Cadastrar
          </Button>
        </ButtonDiv>
      </Form>
    </Modal>
  )
}

export default RegisterUser
